import React from 'react'

import BreadCrumb from 'src/components/Breadcrumb'
import Highlight from 'src/components/Layout/NewHighlight'
import { PrivacyPolicy } from 'src/components/PrivacyPolicy'
import Layout from 'src/layouts/BaseLayout'

import cookiesPolicyENHtml from '../../../../components/PrivacyPolicy/cookies-policy.html'
import cookiesPolicyENMenu from '../../../../components/PrivacyPolicy/cookies-policy.json'

import { CustomStyle } from './../style'

import usePageQuery from './../pageQuery'
import pageContext from './pageContext.json'

function PoliticaDeCookies () {
  const data = usePageQuery()
  const title = 'Cookies Policy'
  const htmlContent = cookiesPolicyENHtml
  const menuContent = cookiesPolicyENMenu

  return (
    <Layout pageContext={pageContext}>
      <Highlight
        customStyle={CustomStyle}
        highlightContext={pageContext}
        bannerDesktop={data.bannerDesktop.fluid}
        bannerMobile={data.bannerMobile.fluid}
      >
        <div className='row justify-content-between align-items-center'>
          <div className='col-12'>
            <BreadCrumb
              sectionName={title}
              type='information'
              text='Privacy center'
              link='/en/privacy-policy/cookies/'
            />
          </div>
          <div className='col-12 mb-2 mb-lg-5'>
            <h1 className='fs-30 fs-md-50 text-white fw-600'>{title}</h1>
          </div>
        </div>
      </Highlight>
      <section className='py-md-4'>
        <div className='container'>
          <PrivacyPolicy
            title={title}
            htmlContent={htmlContent}
            menuContent={menuContent}
          />
        </div>
      </section>
    </Layout>
  )
}

export default PoliticaDeCookies
